<template>
	<div class="course-wrapper">
		<div class="course-wrapper-title">
			<span>组织管理</span>
		</div>
		<div class="course-wrapper-body">
			<!-- 功能按钮 -->
			<div class="course-wrapper-function">
				<div class="function-left">
					<el-input
						class="input-search"
						placeholder="请输入组织名称"
						clearabel
					></el-input>

					<el-button
						type="primary"
						icon="el-icon-search"
					>搜索</el-button>

					<el-button
						icon="el-icon-refresh-left"
					>重置</el-button>
				</div>
				<div class="function-right">
					<el-button
						@click="handleOrganizatioButton('update')"
						icon="el-icon-refresh"
					>同步</el-button>

					<el-button
						@click="handleOrganizatioButton('insert')"
						type="primary"
						icon="el-icon-plus"
					>新建组织</el-button>
				</div>
			</div>

			<el-table
				:data="organizationTableData"
				border
			>
				<el-table-column
					label="序号"
					type="index"
					width="80"
					align="center">
				</el-table-column>

				<el-table-column
					prop=""
					label="组织名称">
				</el-table-column>

				<el-table-column
					prop=""
					label="编号">
				</el-table-column>

				<el-table-column
					prop=""
					label="成员数量">
				</el-table-column>

				<el-table-column
					prop=""
					label="备注">
				</el-table-column>

				<el-table-column
					prop=""
					label="排序">
				</el-table-column>

				<el-table-column
					prop=""
					label="状态">
				</el-table-column>

				<el-table-column
					prop=""
					label="操作"
					class-name="table-handle"
					align="center"
					width="200">
					<template>
						<el-button
							type="text">编辑</el-button>
						<el-button
							type="text"
							class="button-danger">删除</el-button>
						<el-button
							type="text">下级组织</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<organizatio-dialog :organizatioHandleData="organizatioHandleData"></organizatio-dialog>
	</div>
</template>

<script>
import organizatioDialog from './organizatioDialog.vue';
export default {
	name: 'organizationManage',
	components: {
		organizatioDialog,
	},
	data() {
		return {
			organizationTableData: [
				{},
				{},
			],
			organizatioHandleData: {},
		}
	},
	methods: {
		handleOrganizatioButton(handleName) {
			switch (handleName) {
				case 'insert':
					this.organizatioHandleData = {
						handle: 'insert',
					}
					break;

				default:
					break;
			}
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/common_c.scss';
.course-wrapper-body {
	.course-wrapper-function {
		.function-left {
			.input-search {
				margin-right: 10px;
			}
		}
	}
}
</style>
