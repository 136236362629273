<template>
  <div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :before-close="dialogBeforeClose">
      <div>
        <el-form :data="dialogForm" ref="organizatio" :rules="dialogRules" label-width="5.20833vw">
          <el-form-item label="组织名称：" prop="organizatioName">
            <el-input v-model="dialogForm.organizatioName"></el-input>
          </el-form-item>
          <el-form-item label="排序：" prop="sort">
            <el-input v-model="dialogForm.sort"></el-input>
          </el-form-item>
          <el-form-item label="备注：" prop="note">
            <el-input v-model="dialogForm.note"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    organizatioHandleData: Object
  },
  data() {
    return {
      dialogTitle: '新建组织',
      dialogVisible: false, // 添加｜编辑 编辑框显隐
      dialogForm: {
        organizatioName: '',
        sort: '',
        note: '',
      },
      dialogRules: {
        organizatioName: [
          {required: true, message:'组织名称不能为空', trigger: 'blur'}
        ]
      },
    }
  },
  created() {
    console.log(this);
  },
  methods: {
    dialogBeforeClose() {
      this.dialogVisible = false;
      this.dialogForm.organizatioName = '';
      this.dialogForm.sort = '';
      this.dialogForm.note = '';
      this.$refs['organizatio'].clearValidate();
    }
  },
  watch: {
    organizatioHandleData(data) {
      this.dialogVisible = true;
      console.log(data);
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
